import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import SideDrawer from "./SideDrawer";
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { yellow, gray } from '../assets/estilos/Colors';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles, Container, Hidden, List, ListItem, ListItemText } from "@material-ui/core";
import { connect } from 'react-redux';
import { ButtonYellow } from '../components/Button';
import { logout } from '../actions/AuthActions';

const useStyles = makeStyles((theme) => ({
    navbarDisplayFlex: {
        display: `flex`,
        justifyContent: `space-between`,
        alignItems: "center",
        '& img': {
            cursor: `pointer`
        }
    },
    navDisplayFlex: {
        display: `flex`,
        justifyContent: `space-between`
    },
    linkText: {
        textDecoration: `none`,
        textTransform: `uppercase`,
        color: gray.default,
        '& span': {
            fontSize: `0.9rem`,
            fontWeight: `bold`
        }
    },
    appBar: {
        backgroundColor: `#ffffff`,
        color: gray.default,
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: `solid 6px ${yellow.logo}`,
        boxShadow: '1px 1px 8px 0 rgba(39,58,80,0.2)',
        [theme.breakpoints.down('sm')]: {
            minHeight: `100px`
        },
        '& img': {
            maxHeight: 60
        },
        '& .MuiListItem-gutters': {
            top: `50%`,
            transform: `translateY(-50%)`
        }
    },
    toolbar: {
        minHeight: `140px`,
        [theme.breakpoints.down('sm')]: {
            minHeight: `100px`
        },
    }
}));

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const navLinks = [
    { title: `Home`, path: `/` },
    { title: `Quem Somos`, path: `/quem-somos` },
    { title: `Sou Candidato`, path: `/candidato` },
    { title: `Sou Empresa`, path: `/empresa` },
];


const HideAppBar = (props) => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <React.Fragment>
            <HideOnScroll {...props}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Container maxWidth="lg" className={classes.navbarDisplayFlex}>
                            <img onClick={() => history.push('/')} src='/logo.svg' alt="Mais Estágios" />
                            <Hidden smDown>
                                <List
                                    component="nav"
                                    aria-labelledby="main navigation"
                                    className={classes.navDisplayFlex}
                                >
                                    {navLinks.map(({ title, path }) => (
                                        <Link to={path} key={title} className={classes.linkText}>
                                            <ListItem button>
                                                <ListItemText primary={title} />
                                            </ListItem>
                                        </Link>
                                    ))}
                                    <a className={classes.linkText} href="https://people.talentbrand.com.br/c/mais-estagios" rel="noopener noreferrer" target="_blank">
                                        <ListItem button>
                                            <ListItemText primary={"Vagas"} />
                                        </ListItem>
                                    </a>
                                    <LoginCP {...props} />
                                </List>
                            </Hidden>
                            <Hidden mdUp>
                                <SideDrawer componente={<LoginCP {...props} />} navLinks={navLinks} />
                            </Hidden>
                        </Container>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Toolbar className={classes.toolbar} />
        </React.Fragment>
    );
}

const LoginCP = ({ auth, logout }) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <a
                href="https://facilcon.jelastic.saveincloud.net/"
                className={classes.linkText}
                rel="noopener noreferrer"
                target="_blank"
            >
                <ListItem>
                    <ButtonYellow text="Login" fontSize="1rem" />
                </ListItem>
            </a>
        </React.Fragment>
    )

    // if(!auth.token){
    //     return(
    //         <React.Fragment>
    //             <a
    //                 href="javascript:void(0)"
    //                 className={classes.linkText}
    //                 onClick={()=>history.push({
    //                     pathname:"/login",
    //                     state:{
    //                         back: history.location.pathname
    //                     }
    //                 })}
    //             >
    //                 <ListItem>
    //                     <ButtonYellow text="Login" fontSize="1rem" />
    //                 </ListItem>
    //             </a>
    //         </React.Fragment>
    //     )
    // }else{
    //     return(
    //         <React.Fragment>
    //             <Link to="#" className={classes.linkText}>
    //                 <ListItem>
    //                     <AccountCircle /> <span style={{marginLeft:`5px`}}>{auth.nome.split(" ")[0]}</span>
    //                 </ListItem>
    //             </Link>
    //             <Link onClick={()=> logout()} to="#" className={classes.linkText}>
    //                 <ListItem>
    //                     <ExitToApp />
    //                 </ListItem>
    //             </Link>
    //         </React.Fragment>
    //     )
    // }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { logout })(HideAppBar);
